.lockerDisplay{
    background-color: white;
    margin-top: 1rem;
    padding: 10px;
}
.EditButton{
    float: right;
    outline: none;
    background-color: #002857;
    color: white;
    padding: 3px 20px;
    border: none;
    border-radius: 3px;
  }