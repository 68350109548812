.externalLink{
    color: #002758;
    text-decoration: none;
}
.externalLink:hover{
    color: #002758;
    text-decoration: underline;
}
.passWordChangeForm{
    border: 1px solid #002758;
    padding: 30px 10px 30px 10px;
    border-radius: 2px;
    background-color: white;
    width: 350px;
}

input[type='text'] {
    margin-left: 0;
    margin-top: 0;
}

input[type='password'] {
    margin-left: 0;
    margin-top: 0;

}