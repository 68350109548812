.readData{
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 10px 10px 10px 10px;
    margin-top: 5PX;
    width: 100%;
}
.fetchedData{
    width: 80%;
    display: flex;
    
    
}

.actionBut {
    width: 100px;
   margin-left: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row;
    /* flex-wrap: wrap; */
}

.actionBut button{
    margin-right: 0px;
    margin-left: 20px;
    padding: 5px 15px;
    border: none;
    background-color: #002857;
    color: white;
    border-radius: 3px;
    height: auto;
   
}
.delButt{
    background-color: #002857;
    color: white;
}
.headingName{
    width: 150px;
}
.inputData{
    width: 60%;
}
.inputData p{
   margin-bottom: 2px;
}