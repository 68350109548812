.passwordForm {
    background-color: white;
    border: 1px solid #002758;
    padding-bottom: 10px;
    margin-top: 5px;
}
.formfields{
    display: flex;
    flex-wrap: wrap;

}
.formfields > div{
   width: 250px !important;
   margin-right: .5rem;
}

.formfields input[type='text']{ 
    width: 250px !important;
    margin-bottom: 0 !important;
    border: 1px solid #002758;
    border-radius: 0;
}
.formfields input[type='date']{
    width: 250px !important;
    margin-bottom: 0 !important;
    border: 1px solid #002758;
}